#fontblue button:hover {
 background-color: #0395d1 !important;
 color:white !important;
 border:2px solid #0395d1;
 border-radius: 25px
}

#fontblue button {
 background-color: white !important;
 color:#0395d1!important;
 border:2px solid #0395d1;
 border-radius: 25px
}

 /* a:hover {
 background-color: white !important;
 color:black!important;
} */
