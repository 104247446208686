a.effect-underline:after {
	content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 3px solid #0099CC;
  margin-top: 10px;
  opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0.1);
	transform: scale(0.1);
}

a.effect-underline-active:after {
	content: '';
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 3px solid #0099CC;
  margin-top: 10px;
  opacity: 1;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(0.1);
	transform: scale(1);
}



a.effect-underline:hover:after {
  opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
}

@media(max-width: 768px) {
 a.btn-mix{
	 color:#424242
 }
}

@media(min-width: 768px) {

	$bg-color: #fff;

	[class*="btn-"] {
	outline:0;
	border-radius: 50px;
	font-size: 0.68em;
	font-weight: 500;
	letter-spacing: 2px;
	overflow: hidden;
	position: relative;
	padding: 2em 3em;
	text-transform: uppercase;
	-webkit-tap-highlight-color: transparent;
	transition: all 500ms ease-in-out;
	&::after, &::before {
		content: "";
		position: absolute;
	}
	&:hover, &:focus {
		background: rgba(255, 255, 255, 0.8);
	}
	}

	[class*="btn-mix"] {
	&::after {
		background: $bg-color;
		border-radius: 120px;
		content: attr(data-text);
		color: #424242;
		padding: 1.6em 2.5em;
		top: 0.4em;
		left: 0.5em;
		-moz-user-select: none;
		user-select: none;
		user-drag: none;
	}
	&::before {
		background: transparent;
		border-radius: 100%;
		top: 50%;
		left: 50%;
		box-shadow: 0 1em 2em 3em rgba(255, 50, 0, 0.5),
								4em 0em 2em 2em rgba(155, 50, 0, 0.6),
								-4em -2em 2em 2em rgba(255, 0, 150, 0.5),
								-4em 2em 2em 4em rgba(0, 0, 150, 0.5),
								0 -3em 2em 3em rgba(120, 250, 0, 0.5),
								2em -1em 2em 2em rgba(50, 10, 200, 0.5);
		width: 0;
		height: 0;
		animation: mix 5s linear infinite;
	}
	}

	@keyframes mix {

	30% {
		box-shadow: 0 -1em 2em 3em rgba(255, 0, 200, 0.5),
								4em -1em 2em 3em rgba(255, 50, 0, 0.8),
								-4em 2em 3em 2em rgba(155, 200, 0, 0.5),
								-4em -2em 2em 2em rgba(200, 0, 150, 0.6),
								0 3em 2em 2em rgba(20, 250, 0, 0.5),
								2em 1em 2em 2em rgba(50, 10, 250, 0.5);
	}

	60% {
		box-shadow: 2em 1em 2em 3em rgba(55, 0, 200, 0.5),
								3em 1em 2em 3em rgba(25, 150, 0, 0.6),
								3em -2em 3em 3em rgba(155, 200, 0, 0.5),
								-4em 2em 2em 3em rgba(200, 0, 50, 0.6),
								0 -3em 2em 3em rgba(200, 250, 0, 0.5),
								-2em 1em 2em 3em rgba(50, 210, 250, 0.6);
	}

	}

	.btn-mix-noborder {
	  &::after {
	    background: transparent !important;
	    color: #fff !important;

	  }
	}
	.btn-mix-noRadius {
	border-radius: 0 !important;
	&::after, &::before {
		border-radius: 0 !important;
	}
	}
}
