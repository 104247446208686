.mushroom {
    width: 250px;
    height: auto;
    max-width: 100vh;

    background: url("../../assets/mushroom.png") no-repeat;
}
.mushroom:hover {
  width: 250px;
  height: auto;
  max-width: 100;
    background: url("../../assets/mushroom-2.png") no-repeat;
}
