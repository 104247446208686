

.custom-counter {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.custom-counter li {
  counter-increment: step-counter;
  margin-bottom: 10px;
}

.custom-counter li::before {
  content: counter(step-counter);
  margin-right: 5px;
  font-size: 80%;
  background-color: #64b5f6;
  color: white;
  font-weight: bold;
  padding: 3px 8px;
  border-radius: 3px;
}
