body {
	background:#fff;
}

a.border.nav-link.border-light.rounded.mr-1 {
  color: #3F729B;
}

a.border.nav-link.border-light.rounded.mr-1:hover {
  color: #1C2331;
}

.btn.btn-sm.indigo:hover{
  color: #fff;
}

#minimalistintro .view {
  min-height: 100vh !important;
  height: unset !important;
}

#minimalistintro2 .view {
  min-height: 50vh !important;
	height: unset !important;



}

@media (max-width: 767.98px){
	.navbar-collapse {
		height: 100vh
	}
}
.vl {
  border-right: 2px solid #0099CC;
  height: 150px;
  position: relative;
  right: 50%;
  margin-left: -1px;
  top: 0;
}
