@font-face {
    font-family: 'THSarabunNew';
    src: url('thsarabunnew-webfont.eot');
    src: url('thsarabunnew-webfont.eot?#iefix') format('embedded-opentype'),
         url('thsarabunnew-webfont.woff') format('woff'),
         url('thsarabunnew-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'THSarabunNew';
    src: url('thsarabunnew_bolditalic-webfont.eot');
    src: url('thsarabunnew_bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('thsarabunnew_bolditalic-webfont.woff') format('woff'),
         url('thsarabunnew_bolditalic-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;

}

@font-face {
    font-family: 'THSarabunNew';
    src: url('thsarabunnew_italic-webfont.eot');
    src: url('thsarabunnew_italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('thsarabunnew_italic-webfont.woff') format('woff'),
         url('thsarabunnew_italic-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'THSarabunNew';
    src: url('thsarabunnew_bold-webfont.eot');
    src: url('thsarabunnew_bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('thsarabunnew_bold-webfont.woff') format('woff'),
         url('thsarabunnew_bold-webfont.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;

}

.thsarabunnew{
   font-family: 'THSarabunNew', sans-serif;
}