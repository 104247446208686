#single {
  background-color: #fff
}

#single .navbar.scrolling-navbar {
  background-color: #6f7782;
}


#single #post {
  padding-top: 6rem;
}

#single #post a,
#single #recent a,
#single #comments a {
  color: inherit;
  text-decoration: none;
}

#single .avatar {
  max-width: 100px;
}

#single footer.page-footer {
  background-color: #6f7782;
}

#single .footer-copyright {
  margin-top: -1.5rem;
}